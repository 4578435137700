.popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup-body {
    position: absolute;
    
    bottom: 5%;
    top: 15%;
    
    margin: auto;
    border-radius: 15px;
    border-color: black;
    background: white;
    
}